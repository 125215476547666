<template>
  <div>
    404, страница не найдена
  </div>
</template>

<script>
  export default {
    name: "pageNotFound"
  }
</script>

<style scoped>

</style>
